// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
@import url('https://fonts.googleapis.com/css?family=Exo');
@import url('https://fonts.googleapis.com/css?family=Exo|Roboto+Condensed');

body {
    font-family: 'Exo', sans-serif;
    font-weight: normal;
    
  }
  
  h1,h2,h3,h4,h5,h6,.button {

    font-family: 'Roboto Condensed', sans-serif;
    font-size: normal;
    
  }